<template>
    <div>
        <div class="container-fluid bottom-space default-width">
            <div class="row align-items-start bottom-space">
                <DropDownX
                    v-model="form.systeemhuis"
                    :dropDown="form.systeemhuisPost"
                    :h3Key="'Formulier.Systeemhuis'"
                    :h3Value="'Systeemhuis'"
                    :searchable="true"
                    required="'systeemhuis'"
                    :validate="validate"
                    :optionsValue="systeemhuizenSelector"
                    :placeholderKey="'Formulier.Selecteer systeemhuis'"
                    :placeholderValue="'Selecteer systeemhuis'"
                    ref="dropDown-systeemhuis"
                />
                <DropDownX
                    v-model="form.dorpel"
                    :dropDown="form.dorpelPost"
                    :h3Key="'Formulier.Dorpel'"
                    :h3Value="'Dorpel'"
                    :searchable="true"
                    required="dorpel"
                    :validate="validate"
                    :optionsValue="dorpelsSelector"
                    :placeholderKey="'Formulier.Selecteer dorpel'"
                    :placeholderValue="'Selecteer dorpel'"
                    ref="dropDown-dorpel"
                />
                <DropDownX
                    v-model="form.aantalStijlen"
                    :dropDown="form.aantalStijlenPost"
                    :h3Key="'Formulier.Aantal kozijnstijlen'"
                    :h3Value="'Aantal kozijnstijlen'"
                    :optionsValue="aantalKozijnStijlenSelector"
                    required="aantalStijlen"
                    :validate="validate"
                    :placeholderKey="'Formulier.Selecteer aantal'"
                    :placeholderValue="'Selecteer aantal'"
                    ref="dropDown-aantalStijlen"
                />
                <DropDownX
                    v-model="form.pakking"
                    :dropDown="form.pakkingPost"
                    :h3Key="'Formulier.Pakking'"
                    :h3Value="'Pakking'"
                    :optionsValue="pakkingenSelector"
                    required="pakking"
                    :validate="validate"
                    :placeholderKey="'Formulier.Selecteer pakking'"
                    :placeholderValue="'Selecteer pakking'"
                    ref="dropDown-pakking"
                />
                <CheckboxInputX
                    v-if="showAfwatering"
                    v-model="form.doppen"
                    :checkboxInput="form.doppenPost"
                    :h3Key="'Formulier.Doppen'"
                    :h3Value="'Doppen'"
                    :placeholderKey="'Formulier.Doppen'"
                    :placeholderValue="'Doppen'"
                    ref="checkBox-doppen"
                />
                <DropDownX
                    v-model="form.kleur"
                    :dropDown="form.kleurPost"
                    :h3Key="'Formulier.Kleur'"
                    :h3Value="'Kleur'"
                    :optionsValue="kleurenSelector"
                    required="kleur"
                    :validate="validate"
                    :placeholderKey="'Formulier.Selecteer kleur'"
                    :placeholderValue="'Selecteer kleur'"
                    ref="dropDown-kleur"
                />
                <DropDownX
                    v-model="form.bewerkingLinks"
                    :dropDown="form.bewerkingLinksPost"
                    :h3Key="'Formulier.Bewerking links'"
                    :h3Value="'Bewerking links'"
                    :optionsValue="bewerkingLinksSelector"
                    required="bewerkingLinks"
                    :validate="validate"
                    :placeholderKey="'Formulier.Selecteer bewerking'"
                    :placeholderValue="'Selecteer bewerking'"
                    ref="dropDown-bewerkingLinks"
                />
                <DropDownX
                    v-model="form.bewerkingRechts"
                    :dropDown="form.bewerkingRechtsPost"
                    :h3Key="'Formulier.Bewerking rechts'"
                    :h3Value="'Bewerking rechts'"
                    :optionsValue="bewerkingRechtsSelector"
                    required="bewerkingRechts"
                    :validate="validate"
                    :placeholderKey="'Formulier.Selecteer bewerking'"
                    :placeholderValue="'Selecteer bewerking'"
                    ref="dropDown-bewerkingRechts"
                />
                <TextInputX
                    v-model="form.merk"
                    :textInput="form.merkPost"
                    :h3Key="'Formulier.Merk'"
                    :h3Value="'Merk'"
                    required="merk"
                    :validate="validate"
                    :placeholderKey="'Formulier.Merk'"
                    :placeholderValue="'Merk'"
                    ref="inputText-merk"
                />
                <CheckboxInputX
                    v-model="form.hasNaamOnderaanslag"
                    :checkboxInput="form.hasNaamOnderaanslagPost"
                    :h3Key="'Formulier.Naam onderaanslag'"
                    :h3Value="'Naam onderaanslag'"
                    :placeholderKey="'Formulier.Ja'"
                    :placeholderValue="'Ja'"
                    ref="checkBox-hasNaamOnderaanslag"
                />
                <DropDownX
                    v-model="form.naamOnderaanslag"
                    :dropDown="form.naamOnderaanslagPost"
                    :h3Key="'Formulier.Naam onderaanslag grootte'"
                    :h3Value="'Naam onderaanslag grootte'"
                    :optionsValue="naamOnderaanslagSelector"
                    :required="
                        form.hasNaamOnderaanslag ? 'naamOnderaanslag' : null
                    "
                    :validate="validate"
                    :placeholderKey="'Formulier.Selecteer Naam onderaanslag grootte'"
                    :placeholderValue="'Selecteer naam onderaanslag grootte'"
                    ref="dropDown-naamOnderaanslag"
                />
                <TextInputX
                    v-model="form.aantalDorpels"
                    :textInput="form.aantalDorpelsPost"
                    :h3Key="'Formulier.Aantal dorpels'"
                    :h3Value="'Aantal dorpels'"
                    required="aantal"
                    typeValue="number"
                    :min-value="1"
                    :validate="validate"
                    :placeholderKey="'Formulier.Aantal dorpels'"
                    :placeholderValue="'Aantal dorpels'"
                    ref="inputText-aantal"
                />
            </div>
            <div
                v-if="translate('Formulier.Waarschuwing', '')"
                class="row align-items-start bottom-space warning"
            >
                <div
                    v-html="translate('Formulier.Waarschuwing', '')"
                    class="col-xs-12"
                ></div>
            </div>
        </div>
        <div class="container-fluid bottom-space" :style="neutenWidth">
            <div class="align-items-start bottom-space">
                <div
                    class="d-flex"
                    :class="[
                        {
                            'ready-for-preview': isReadyForPreview,
                        },
                    ]"
                >
                    <div
                        class="p-2"
                        :class="{ 'flex-grow-1': i != 0 }"
                        v-for="(dorpelNeut, i) in dorpelNeuten"
                        :key="i"
                    >
                        <div class="d-flex">
                            <div class="p-2 flex-grow-1" v-if="i != 0">
                                <div
                                    class="neut-maat-bg"
                                    :class="{
                                        'ready-for-preview': getNeut(
                                            dorpelNeut?.neut
                                        )?.background,
                                    }"
                                    :style="
                                        neutBackground(
                                            getNeut(dorpelNeut?.neut)
                                                ?.background
                                        )
                                    "
                                ></div>
                                <div class="neut-maat">
                                    {{ dorpelPanelen[i - 1]?.maat }} mm
                                </div>
                            </div>
                            <div class="p-2">
                                <img
                                    v-if="getNeut(dorpelNeut?.neut)?.image"
                                    :src="getNeut(dorpelNeut?.neut)?.image"
                                />
                                <div v-else class="neut-holder">?</div>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex">
                        <div class="p-2">
                            <img
                                :src="formData.dorpelImages[form.dorpel]"
                                :dorpel="form.dorpel"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid bottom-space default-width">
            <div class="row align-items-start bottom-space">
                <div class="d-flex">
                    <div
                        class="p-2 flex-grow-1 strong"
                        :class="[
                            {
                                'form-part-invalid': hasMaatError,
                            },
                        ]"
                    >
                        {{ translate('Formulier.Totaal', 'Totaal') }}
                        {{ totalMaat }} mm ({{
                            translate('Formulier.Maximaal', 'maximaal')
                        }}
                        {{ maxTotalMaat }} mm)
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid bottom-space" :style="neutenWidth">
            <div class="align-items-start bottom-space">
                <div class="d-flex">
                    <div
                        class="p-2"
                        :class="{ 'flex-grow-1': i != 0 }"
                        v-for="(dorpelNeut, i) in dorpelNeuten"
                        :key="dorpelNeut"
                    >
                        <div class="d-flex">
                            <div
                                class="p-2 flex-grow-1 neut-selector-empty"
                                v-if="i != 0"
                            ></div>
                            <div class="p-2 neut-selector">
                                <DropDownX
                                    :h3Key="'Formulier.Kant'"
                                    :dropDown="dorpelNeut.vormPost"
                                    :h3Value="'Kant'"
                                    v-model="dorpelNeut.vorm"
                                    :index="i"
                                    :optionsValue="selectorSelector"
                                    :required="'vorm[' + i + ']'"
                                    :validate="validate"
                                    :container-class="null"
                                    :placeholderKey="'Formulier.Selecteer kant'"
                                    :placeholderValue="'Selecteer kant'"
                                    :ref="'dropDowns-vorm' + i"
                                />
                                <DropDownX
                                    :h3Key="'Formulier.Neut'"
                                    :dropDown="dorpelNeut.neutPost"
                                    :h3Value="'Neut'"
                                    v-model="dorpelNeut.neut"
                                    :required="'neut' + i"
                                    :validate="validate"
                                    :optionsValue="
                                        neutSelector(
                                            dorpelNeut.vorm,
                                            dorpelNeut.vormType
                                        )
                                    "
                                    :container-class="null"
                                    :placeholderKey="'Formulier.Selecteer neut'"
                                    :placeholderValue="'Selecteer neut'"
                                    :ref="'dropDowns-neut' + i"
                                />
                                <DropDownX
                                    v-model="dorpelNeut.boorPatroon"
                                    :dropDown="dorpelNeut.boorPatroonPost"
                                    :h3Key="'Formulier.Boorpatroon'"
                                    :h3Value="'Boorpatroon'"
                                    :container-class="null"
                                    :optionsValue="boorPatroonSelector"
                                    :required="'boorPatroon' + i"
                                    :validate="validate"
                                    :placeholderKey="'Formulier.Selecteer boorpatroon'"
                                    :placeholderValue="'Selecteer boorpatroon'"
                                    :ref="'dropDowns-boorPatroon' + i"
                                />
                                <DropDownX
                                    v-model="dorpelNeut.vulblok"
                                    :dropDown="dorpelNeut.vulblokPost"
                                    :h3Key="'Formulier.Vulblok'"
                                    :h3Value="'Vulblok'"
                                    :container-class="null"
                                    :optionsValue="vulblokSelector(dorpelNeut)"
                                    :required="
                                        vulblokSelector(dorpelNeut).length > 0
                                            ? 'vulblok' + i
                                            : null
                                    "
                                    :validate="validate"
                                    :placeholderKey="'Formulier.Selecteer Vulblok'"
                                    :placeholderValue="'Selecteer Vulblok'"
                                    :ref="'dropDowns-vulblok' + i"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid bottom-space" :style="neutenWidth">
            <div class="row align-items-start bottom-space">
                <div
                    class="col paneel-holder"
                    v-for="(dorpelPaneel, i) in dorpelPanelen"
                    :key="i"
                >
                    <TextInputX
                        containerClass=""
                        v-model="dorpelPaneel.maat"
                        :textInput="dorpelPaneel.maatPost"
                        :h3Key="'Formulier.Maat'"
                        :h3Value="'Maat'"
                        required="maat"
                        typeValue="number"
                        :min-value="1"
                        :validate="validate"
                        :placeholderKey="'Formulier.Maat'"
                        :placeholderValue="'Maat'"
                        :ref="'inputTexts-maat' + i"
                    />
                    <TextInputX
                        containerClass=""
                        v-model="dorpelPaneel.bevestigingsGaten"
                        :textInput="dorpelPaneel.bevestigingsGatenPost"
                        :h3Key="'Formulier.Bevestigingsgaten'"
                        :h3Value="'Bevestigingsgaten'"
                        typeValue="number"
                        :min-value="0"
                        :validate="validate"
                        :placeholderKey="'Formulier.Bevestigingsgaten'"
                        :placeholderValue="'Bevestigingsgaten'"
                        :ref="'inputTexts-bevestigingsGaten' + i"
                    />
                    <CheckboxInputX
                        v-model="dorpelPaneel.afwatering"
                        :checkboxInput="dorpelPaneel.afwateringPost"
                        :h3Key="'Formulier.Afwatering'"
                        :h3Value="'Afwatering'"
                        :container-class="null"
                        :placeholderKey="'Formulier.Afwatering'"
                        :placeholderValue="'Ja'"
                        :ref="'checkBoxs-afwatering' + i"
                    />
                    <DropDownX
                        v-model="dorpelPaneel.epdmStrip"
                        :dropDown="dorpelPaneel.epdmStripPost"
                        :h3Key="'Formulier.EPDM strip'"
                        :h3Value="'EPDM strip'"
                        :container-class="null"
                        :optionsValue="epdmStripSelector"
                        :placeholderKey="'Formulier.Selecteer EPDM strip'"
                        :placeholderValue="'Selecteer EPDM strip'"
                        :ref="'dropDowns-epdmStrip' + i"
                    />
                    <DropDownX
                        v-model="dorpelPaneel.amStrip"
                        :dropDown="dorpelPaneel.amStripPost"
                        :h3Key="'Formulier.AM strip'"
                        :h3Value="'AM strip'"
                        :container-class="null"
                        :optionsValue="amStripSelector"
                        :placeholderKey="'Formulier.Selecteer AM strip'"
                        :placeholderValue="'Selecteer AM strip'"
                        :ref="'dropDowns-amStrip' + i"
                    />
                </div>
            </div>
        </div>
        <div class="container-fluid bottom-space default-width">
            <div class="row align-items-start bottom-space">
                <div class="row align-items-start">
                    <div class="col-12">
                        <h3>
                            {{
                                translate('Formulier.Sluitplaat', 'Sluitplaat')
                            }}
                        </h3>
                    </div>
                    <DropDownX
                        v-model="form.sluitplaat"
                        :dropDown="form.sluitplaatPost"
                        :h3Key="'Formulier.Sluitplaat'"
                        :h3Value="'Sluitplaat'"
                        :showH3="false"
                        :optionsValue="sluitplatenSelector"
                        :placeholderKey="'Formulier.Selecteer bewerking'"
                        :placeholderValue="'Selecteer bewerking'"
                        ref="dropDown-sluitplaat"
                    />
                </div>
                <div v-if="form.sluitplaat" class="row align-items-start">
                    <TextInputX
                        v-model="form.sluitplaatFrezenLengte"
                        :textInput="form.sluitplaatFrezenLengtePost"
                        :h3Key="'Formulier.Frezen lengte'"
                        :h3Value="'Frezen lengte'"
                        :required="hasSluitplaat('sluitplaatFrezenLengte')"
                        typeValue="number"
                        :min-value="0"
                        :validate="validate"
                        :placeholderKey="'Formulier.Frezen lengte'"
                        :placeholderValue="'Frezen lengte'"
                        ref="inputText-sluitplaatFrezenLengte"
                    />
                    <TextInputX
                        v-model="form.sluitplaatFrezenHoogte"
                        :textInput="form.sluitplaatFrezenHoogtePost"
                        :h3Key="'Formulier.Frezen hoogte'"
                        :h3Value="'Frezen hoogte'"
                        :required="hasSluitplaat('sluitplaatFrezenHoogte')"
                        typeValue="number"
                        :min-value="0"
                        :validate="validate"
                        :placeholderKey="'Formulier.Frezen hoogte'"
                        :placeholderValue="'Frezen hoogte'"
                        ref="inputText-sluitplaatFrezenHoogte"
                    />
                    <TextInputX
                        v-model="form.sluitplaatPositieBegin"
                        :textInput="form.sluitplaatPositieBeginPost"
                        :h3Key="'Formulier.Vanaf links begin'"
                        :h3Value="'Vanaf links begin'"
                        :required="hasSluitplaat('sluitplaatPositieBegin')"
                        typeValue="number"
                        :min-value="0"
                        :validate="validate"
                        :placeholderKey="'Formulier.Vanaf links begin'"
                        :placeholderValue="'Vanaf links begin'"
                        ref="inputText-sluitplaatPositieBegin"
                    />
                    <TextInputX
                        v-model="form.sluitplaatVoorkantDam"
                        :textInput="form.sluitplaatVoorkantDamPost"
                        :h3Key="'Formulier.Vanaf voorkant dam'"
                        :h3Value="'Vanaf voorkant dam'"
                        :required="hasSluitplaat('sluitplaatVoorkantDam')"
                        typeValue="number"
                        :min-value="0"
                        :validate="validate"
                        :placeholderKey="'Formulier.Vanaf voorkant dam'"
                        :placeholderValue="'Vanaf voorkant dam'"
                        ref="inputText-sluitplaatVoorkantDam"
                    />
                </div>
            </div>
        </div>
        <div class="container-fluid bottom-space default-width">
            <div class="row align-items-start bottom-space">
                <div class="row align-items-start">
                    <TextInputX
                        v-model="form.extraArtikelen"
                        :textInput="form.extraArtikelenPost"
                        :h3Key="'Formulier.Extra artikelen'"
                        :h3Value="'Extra artikelen'"
                        regex="^[a-zA-Z0-9\,\s\-]*$"
                        :validate="validate"
                        :placeholderKey="'Formulier.Extra artikelen'"
                        :placeholderValue="'Extra artikelen'"
                        ref="inputText-extraArtikelen"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DropDownX from './DropDownX'
import TextInputX from './TextInputX'
import CheckboxInputX from './CheckboxInputX'
import { mapGetters } from 'vuex'

export default {
    name: 'Neut Form',
    components: {
        DropDownX,
        TextInputX,
        CheckboxInputX,
    },
    props: {
        initialForm: {
            type: Object,
            required: true,
        },
        validate: {
            type: Boolean,
            required: false,
        },
    },
    data() {
        return {
            form: {},
            bewerkingLinks: null,
            bewerkingRechts: null,
            selectorSelector: [
                {
                    label: 'Links',
                    value: 'L',
                    isDefault: false,
                },
                {
                    label: 'Midden',
                    value: 'M',
                    isDefault: false,
                },
                {
                    label: 'Rechts',
                    value: 'R',
                    isDefault: false,
                },
            ],
            naamOnderaanslag: [
                {
                    label: 'Groot',
                    value: 'groot',
                    isDefault: false,
                },
                {
                    label: 'Klein',
                    value: 'klein',
                    isDefault: false,
                },
            ],
            maxTotalMaat: 6200,
            errors: [],
            panelenWidth: null,
            neutenWidth: null,
            isValid: false,
        }
    },
    created: function () {
        this.form = this.initialForm
        this.resizeHandler()
    },
    mounted() {
        window.addEventListener('resize', this.resizeHandler)
    },
    unmounted() {
        window.removeEventListener('resize', this.resizeHandler)
    },
    methods: {
        Testen() {
            console.log('Testen', this.form.systeemhuisPost.postValue)
        },
        resizeHandler() {
            const neutenWidth = this.dorpelNeuten.length * 320
            const windowWidth = window.innerWidth
            const defaultWidth = 1600
            if (windowWidth > defaultWidth) {
                if (neutenWidth > defaultWidth) {
                    this.neutenWidth = 'width:' + neutenWidth + 'px'
                } else {
                    this.neutenWidth = 'width:1600px'
                }
            } else {
                if (neutenWidth > windowWidth) {
                    this.neutenWidth = 'width:' + neutenWidth + 'px'
                } else {
                    this.neutenWidth = ''
                }
            }
        },
        isStringValid(val) {
            if (!this.validate) {
                return true
            }
            return val && val.trim() != ''
        },
        isIntValid(val, minVal) {
            if (!this.validate) {
                return true
            }
            const castedVal = parseInt(val, 10)
            return val === castedVal && castedVal >= minVal
        },
        postData() {
            let postModel = {
                systeemhuis: this.form.systeemhuisPost.formComponent.postValue,
                dorpel: this.form.dorpelPost.formComponent.postValue,
                dorpelImage: this.formData.dorpelImages[this.form.dorpel],
                aantalKozijnen:
                    this.form.aantalStijlenPost.formComponent.postValue,
                pakking: this.form.pakkingPost.formComponent.postValue,
                doppen: this.form.doppenPost.formComponent?.postValue,
                kleur: this.form.kleurPost.formComponent.postValue,
                bewerkingLinks:
                    this.form.bewerkingLinksPost.formComponent.postValue,
                bewerkingRechts:
                    this.form.bewerkingRechtsPost.formComponent.postValue,
                merk: this.form.merkPost.formComponent.postValue,
                hasNaamOnderaanslag:
                    this.form.hasNaamOnderaanslagPost.formComponent.postValue,
                naamOnderaanslag:
                    this.form.naamOnderaanslagPost.formComponent.postValue,
                aantalDorpels:
                    this.form.aantalDorpelsPost.formComponent.postValue,
                sluitplaat: this.form.sluitplaatPost.formComponent.postValue,
                sluitplaatpositieX:
                    this.form.sluitplaatFrezenLengtePost.formComponent
                        ?.postValue,
                sluitplaatpositieY:
                    this.form.sluitplaatFrezenHoogtePost.formComponent
                        ?.postValue,
                breedtesluitplaat:
                    this.form.sluitplaatPositieBeginPost.formComponent
                        ?.postValue,
                hoogtesluitplaat:
                    this.form.sluitplaatVoorkantDamPost.formComponent
                        ?.postValue,
                extraArtikelen:
                    this.form.extraArtikelenPost.formComponent.postValue,
                neuten: [],
                panelen: [],
            }
            for (let i = 0; i < this.dorpelNeuten.length; i++) {
                const dorpelNeut = this.getNeut(this.dorpelNeuten[i].neut)
                postModel.neuten.push({
                    kant: this.dorpelNeuten[i].vormPost.formComponent.postValue,
                    neut: this.dorpelNeuten[i].neutPost.formComponent.postValue,
                    boorPatroon:
                        this.dorpelNeuten[i].boorPatroonPost.formComponent
                            .postValue,
                    vulblok:
                        this.dorpelNeuten[i].vulblokPost.formComponent
                            .postValue,
                    image: dorpelNeut?.image,
                    background: dorpelNeut?.background,
                })
            }

            for (let i = 0; i < this.dorpelPanelen.length; i++) {
                postModel.panelen.push({
                    maat: this.dorpelPanelen[i].maatPost.formComponent
                        .postValue,
                    bevestigingsGaten:
                        this.dorpelPanelen[i].bevestigingsGatenPost
                            .formComponent.postValue,
                    afwatering:
                        this.dorpelPanelen[i].afwateringPost.formComponent
                            .postValue,
                    epdmStrip:
                        this.dorpelPanelen[i].epdmStripPost.formComponent
                            .postValue,
                    amStrip:
                        this.dorpelPanelen[i].amStripPost.formComponent
                            .postValue,
                })
            }
            return postModel
        },
        find(arr, val) {
            return arr?.find((obj) => val && obj.value.postValue == val)
        },
        getDropDown(arr) {
            const result = []
            if ((arr?.length ?? 0) > 0) {
                for (let i = 0; i < arr.length; i++) {
                    result.push({
                        label: arr[i].label,
                        value: arr[i].value.postValue,
                        isDefault: arr[i].value.isDefault,
                    })
                }
            }
            return result
        },
        getBewerking(vorm) {
            return this.currentSysteemhuis.neuten.find(
                (obj) =>
                    (obj.dorpels.lengrh === 0 ||
                        obj.dorpels.includes(this.currentDorpel?.label)) &&
                    obj.vorm == vorm
            )?.bewerking
        },
        timeout(ms) {
            return new Promise((resolve) => setTimeout(resolve, ms))
        },
        validateForm() {
            console.log('validateNeutForm 1')
            const forms = []
            forms.push(this.form)
            forms.push(...this.form.dorpelNeuten)
            forms.push(...this.form.dorpelPanelen)
            for (let i = 0; i < forms.length; i++) {
                const props = Object.getOwnPropertyNames(forms[i])
                for (let p = 0; p < props.length; p++) {
                    if (props[p].endsWith('Post')) {
                        if (!forms[i][props[p]].isValid) {
                            console.log('validateNeutForm 2', i, props[p])
                            this.form.isValid = false
                            return
                        }
                    }
                }
            }
            this.form.isValid = true
        },
    },
    computed: {
        ...mapGetters(['formData', 'translate', 'currentLanguage']),
        systeemhuizenSelector() {
            return this.getDropDown(this.formData?.systeemhuizen)
        },
        currentSysteemhuis() {
            return this.find(
                this.formData?.systeemhuizen,
                this.form?.systeemhuis
            )?.value
        },
        getNeut() {
            {
                return (label) => {
                    return this.currentSysteemhuis?.neuten.find(
                        (obj) => label && obj.postValue == label
                    )
                }
            }
        },
        neutBackground() {
            return (backgroundImage) => {
                if (backgroundImage) {
                    return {
                        backgroundImage: 'url(' + backgroundImage + ')',
                    }
                }
                return null
            }
        },
        naamOnderaanslagSelector() {
            if (this.form.hasNaamOnderaanslag) {
                return this.naamOnderaanslag
            }
            return []
        },
        dorpelsSelector() {
            return this.getDropDown(this.currentSysteemhuis?.dorpels)
        },
        currentDorpel() {
            return this.find(this.currentSysteemhuis?.dorpels, this.form.dorpel)
        },
        aantalKozijnStijlenSelector() {
            return this.getDropDown(this.formData?.aantalKozijnStijlen)
        },
        neutSelector() {
            return (side, sideType) => {
                if (this.isReadyForPreview) {
                    let vorm = ''
                    switch (side) {
                        case 'M':
                            vorm = 'Midden'
                            break
                        case 'R':
                            vorm = 'Rechts'
                            break
                        default:
                            vorm = 'Links'
                            break
                    }
                    const bewerking = []
                    switch (side + sideType) {
                        case 'LR':
                        case 'LL':
                            bewerking.push(this.form.bewerkingLinks)
                            break
                        case 'RR':
                        case 'RL':
                            bewerking.push(this.form.bewerkingRechts)
                            break
                    }

                    const items = this.currentSysteemhuis?.neuten.filter(
                        (neut) =>
                            (!neut.dorpels ||
                                neut.dorpels.includes(
                                    this.currentDorpel?.label
                                )) &&
                            (bewerking.length == 0 ||
                                bewerking.includes(neut.bewerking)) &&
                            neut.vorm === vorm
                    )

                    return this.getDropDown(items)
                }
                return []
            }
        },
        pakkingenSelector() {
            return this.getDropDown(this.formData?.pakkingen)
        },
        kleurenSelector() {
            return this.getDropDown(this.formData?.kleuren)
        },

        bewerkingLinksSelector() {
            return this.getDropDown(this.formData?.bewerkingLinks)
        },
        bewerkingRechtsSelector() {
            return this.getDropDown(this.formData?.bewerkingRechts)
        },
        stijlen() {
            return (
                this.find(
                    this.formData.aantalKozijnStijlen,
                    this.form.aantalStijlen
                )?.value?.value ?? []
            )
        },
        dorpelNeuten() {
            return this.form.dorpelNeuten.filter(
                (obj) =>
                    this.stijlen &&
                    (obj.label == 'X' || this.stijlen.includes(obj.label))
            )
        },
        dorpelPanelen() {
            return this.form.dorpelPanelen.filter(
                (obj) => this.stijlen && this.stijlen.includes(obj.label)
            )
        },
        boorPatroonSelector() {
            return this.getDropDown(this.currentSysteemhuis?.boorPatronen)
        },
        vulblokSelector() {
            return (neut) => {
                return this.getDropDown(
                    this.find(
                        this.currentSysteemhuis?.boorPatronen,
                        neut?.boorPatroon
                    )?.value?.value
                )
            }
        },
        epdmStripSelector() {
            return this.getDropDown(
                this.currentSysteemhuis?.epdmStrips.filter(
                    (obj) =>
                        obj.value.value.length == 0 ||
                        obj.value.value.includes(this.currentDorpel?.label)
                )
            )
        },
        amStripSelector() {
            return this.getDropDown(
                this.currentSysteemhuis?.amStrips.filter(
                    (obj) =>
                        obj.value.value.length == 0 ||
                        obj.value.value.includes(this.currentDorpel?.label)
                )
            )
        },
        sluitplatenSelector() {
            return this.getDropDown(
                this.currentSysteemhuis?.sluitplaten.filter(
                    (obj) =>
                        obj.value.value.length == 0 ||
                        obj.value.value.includes(this.currentDorpel?.label)
                )
            )
        },
        isReadyForPreview() {
            return this.form &&
                this.form.systeemhuis &&
                this.form.dorpel &&
                this.form.aantalStijlen &&
                this.form.bewerkingLinks &&
                this.form.bewerkingRechts
                ? true
                : false
        },
        hasSluitplaat() {
            return (prop) => {
                if (this.form.sluitplaat) {
                    return prop
                }
                return null
            }
        },
        showAfwatering() {
            if (!this.currentSysteemhuis || !this.currentDorpel) {
                return false
            }
            return (
                !this.currentSysteemhuis?.afwatering ||
                this.currentSysteemhuis?.afwatering?.includes(this.form.dorpel)
            )
        },
        totalMaat() {
            return this.dorpelPanelen.reduce(
                (a, b) => a + (Math.abs(b.maat) || 0),
                0
            )
        },
        hasMaatError() {
            return this.totalMaat > this.maxTotalMaat
        },
        errorClass() {
            return (prop) => {
                return {
                    'form-prop-error':
                        this.validate && this.errors.includes(prop),
                }
            }
        },
        errorClassDisabled() {
            return (prop, disabled) => {
                return {
                    'form-prop-error': !disabled && this.validate && !prop,
                }
            }
        },
        errorClassMaat() {
            return (prop) => {
                return {
                    'form-prop-error':
                        this.totalMaat >= this.maxTotalMaat ||
                        (this.validate && !prop),
                }
            }
        },
    },
    watch: {
        'form.aantalStijlen'() {
            this.resizeHandler()
        },
        form: {
            handler() {
                this.validateForm()
            },
            deep: true,
        },
    },
}
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
