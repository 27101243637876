<template>
    <Form />
    <Login />
    <link
        href="https://fonts.googleapis.com/css2?family=Source+Sans+3:wght@300;400;500;600;700;800&display=swap"
        ref="stylesheet"
    />
    <link rel="stylesheet" href="https://use.typekit.net/mqn0lgq.css" />
</template>

<script>
import Form from './components/Form.vue'
import Login from './components/Login.vue'
import './scss/main.scss'

export default {
    name: 'App',
    components: {
        Form,
        Login,
    },
}
</script>

<style lang="scss"></style>
