<template>
    <svg
        width="123"
        height="33"
        viewBox="0 0 123 33"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M57.7561 0H51.3272C50.8088 0 50.4977 0.312924 50.4977 0.834465V20.0272C50.4977 23.5736 48.3202 25.4512 44.5873 25.4512C40.8545 25.4512 38.7806 23.5736 38.7806 20.0272V0.834465C38.6769 0.312924 38.3659 0 37.8474 0H31.4186C30.7964 0 30.4854 0.312924 30.4854 0.834465V20.0272C30.4854 27.746 35.6699 32.2312 44.4836 32.2312C53.4011 32.2312 58.5856 27.8503 58.5856 20.0272V0.834465C58.5856 0.312924 58.2745 0 57.7561 0Z"
            fill="#0F6FB5"
        ></path>
        <path
            d="M93.8409 0H87.101C86.5825 0 86.2715 0.208616 86.0641 0.625849L80.7758 15.0204C79.7389 17.8367 78.8057 21.0702 77.9762 23.5736H77.7688C77.043 21.0702 76.0061 17.7324 74.9691 14.9161L69.6809 0.625849C69.5772 0.208616 69.2661 0 68.7477 0H61.9041C61.2819 0 60.9709 0.312924 61.1782 0.834465L72.999 30.9795C73.1027 31.3967 73.5175 31.6054 73.9322 31.6054H81.8127C82.3312 31.6054 82.6423 31.3967 82.746 30.9795L94.5667 0.834465C94.8778 0.312924 94.5667 0 93.8409 0Z"
            fill="#0F6FB5"
        ></path>
        <path
            d="M122.874 30.7709L111.26 0.625849C111.157 0.208616 110.742 0 110.327 0H107.735H105.142H102.55C102.135 0 101.721 0.208616 101.617 0.625849L96.0177 15.1247C95.8103 15.5419 96.0177 15.8548 96.6398 15.8548H102.861C103.276 15.8548 103.587 15.6462 103.795 15.229L103.898 15.0204C104.728 12.7256 105.661 9.80496 106.594 7.19726C108.253 12.0997 110.327 18.2539 110.638 18.9841C110.638 19.0884 110.742 19.297 110.742 19.4013H95.1882C94.7734 19.4013 94.566 19.6099 94.3586 19.9228L90.211 30.6666C90.0036 31.1881 90.211 31.501 90.9368 31.501H97.6767C98.0915 31.501 98.5063 31.2924 98.7136 30.8752L100.58 25.4512H112.816L114.682 30.8752C114.786 31.2924 115.201 31.501 115.719 31.501H122.459C122.874 31.6054 123.185 31.2924 122.874 30.7709Z"
            fill="#0F6FB5"
        ></path>
        <path
            d="M22.0862 14.8117C24.3674 13.6644 25.6117 11.5782 25.6117 8.9705C25.6117 3.33786 21.5677 0 14.7241 0H1.1406C0.518455 0 0.207382 0.312924 0.207382 0.834465V5.42402C0.207382 5.94556 0.518455 6.25848 1.1406 6.25848H13.5835C15.9684 6.25848 17.3164 7.30157 17.3164 9.38773C17.3164 11.5782 15.8647 12.517 13.5835 12.517H7.88052V10.7437C7.88052 10.1179 7.56944 9.80496 7.05099 9.80496H0.829528C0.311073 9.70065 0 10.0136 0 10.5351V13.0385V16.0634V30.6666C0 31.1881 0.311073 31.501 0.933219 31.501H14.8278C22.7083 31.501 27.167 27.8503 27.167 21.6961C27.167 18.3582 25.4043 15.9591 22.0862 14.8117ZM14.3094 25.3469H8.0879V18.0453H13.9983C17.4201 18.0453 18.7681 19.5056 18.7681 21.5918C18.8718 24.1995 17.0053 25.3469 14.3094 25.3469Z"
            fill="#0F6FB5"
        ></path>
    </svg>
</template>
