<template>
    <div :class="containerClass">
        <h3 v-if="h3">{{ h3 }}</h3>
        <input
            v-model="currentValue"
            :placeholder="placeholder"
            class="form-input"
            :type="type"
            :class="styleClass"
            :disabled="disabled"
            :pattern="pattern"
            @blur="blur"
            @focus="focus"
        />
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export class textInputModel {
    constructor() {
        this.formComponent = null
        this.isValid = true
    }
}

export default {
    props: {
        modelValue: null,
        textInput: {
            type: textInputModel,
        },
        h3Key: {
            type: String,
            default: null,
        },
        h3Value: {
            type: String,
            default: null,
        },
        placeholderKey: {
            type: String,
            default: null,
        },
        placeholderValue: {
            type: String,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        required: {
            type: String,
            default: null,
        },
        validate: {
            type: Boolean,
            default: false,
        },
        typeValue: {
            type: String,
            default: 'text',
        },
        pattern: {
            type: String,
            default: null,
        },
        minValue: {
            type: Number,
            default: null,
        },
        regex: {
            type: String,
            default: '',
        },
        containerClass: {
            type: String,
            default: 'col-xl-3 col-sm-6 col-xs-12',
        },
    },
    data() {
        return {
            textInputModel: null,
            currentValue: null,
            type: 'text',
            isValid: true,
        }
    },
    mounted: function () {
        this.textInputModel = this.textInput
        this.textInputModel.formComponent = this
        this.currentValue = this.modelValue
        this.type = this.typeValue
        this.validateValue()
        this.blur()
    },
    unmounted: function () {
        console.log('unmounted', this.h3Key)
        this.isValid = true
        this.textInputModel.isValid = true
    },
    methods: {
        currentValueChanged: function (value) {
            this.$emit('valueChanged', value)
            this.$emit('update:modelValue', value)
        },
        getWorkingDays: function (StartDate, EndDate) {
            let weekdays = [0, 1, 2, 3, 4]
            var days = []
            var currentDate = StartDate
            if (weekdays.includes(currentDate.getDay())) {
                currentDate.setDate(currentDate.getDate() + 1)
            }
            while (currentDate <= EndDate) {
                if (weekdays.includes(currentDate.getDay())) {
                    days.push(currentDate)
                }
                currentDate.setDate(currentDate.getDate() + 1)
            }
            return days.length
        },
        validateValue() {
            switch (this.type) {
                case 'email':
                    this.setIsValid(!this.emailError())
                    return
                case 'number':
                    this.setIsValid(!this.intError())
                    return
                case 'date':
                    this.setIsValid(!this.emailDate())
                    return
                case 'text':
                case 'password':
                    this.setIsValid(!this.stringError())
                    return
            }

            if (!this.required || !this.validate) {
                if (
                    this.minValue == 0 &&
                    !!this.regex &&
                    (!this.currentValue || this.currentValue == 0)
                ) {
                    this.setIsValid(true)
                    return
                }
            }
            this.setIsValid(false)
        },
        setIsValid(isValid) {
            if (this.isValid != isValid) {
                this.isValid = isValid
                this.textInputModel.isValid = isValid
            }
        },
        stringError() {
            const val = this.currentValue?.trim() ?? ''
            if (val === '') {
                if (this.required) {
                    return true
                }
            } else {
                if (this.minValue != null && val < this.minValue) {
                    return true
                }
                if (this.regex && !val.match(new RegExp(this.regex))) {
                    return true
                }
            }
            return false
        },
        emailError() {
            if (!this.stringError()) {
                return !this.currentValue
                    .toLowerCase()
                    .match(
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    )
            }
            return true
        },
        emailDate() {
            const currentValue = this.currentValue?.trim() ?? ''
            if (currentValue === '') {
                if (this.required) {
                    return false
                }
            } else {
                console.log(
                    this.getWorkingDays(
                        new Date(),
                        Date.parse(this.currentValue)
                    )
                )
                return (
                    this.getWorkingDays(
                        new Date(),
                        Date.parse(this.currentValue)
                    ) < this.minValue
                )
            }
            return true
        },
        intError() {
            const currentValue = this.currentValue
                ? this.currentValue.toString().trim()
                : ''
            if (currentValue === '') {
                if (this.required) {
                    return true
                }
            } else {
                let currentInt = parseInt(this.currentValue ?? '0', 10)
                if (this.minValue != null && currentInt < this.minValue) {
                    return true
                }
            }
            return false
        },
        focus() {
            if (this.typeValue == 'date') {
                this.type = 'date'
            }
        },
        blur() {
            if (this.typeValue == 'date') {
                this.type = 'text'
            }
        },
    },
    computed: {
        ...mapGetters(['translate', 'currentLanguage', 'languages']),
        h3() {
            return this.translate(this.h3Key, this.h3Value)
        },
        placeholder() {
            return this.translate(this.placeholderKey, this.placeholderValue)
        },
        styleClass() {
            const styleClass = []
            styleClass.push(this.containerClass)
            if (this.validate && !this.isValid) {
                styleClass.push('form-prop-error')
            }
            return styleClass.join(' ')
        },
        postValue() {
            return {
                label: this.translate(this.h3Key, this.h3Value),
                value: this.getTypedValue,
                valueLabel: this.currentValue?.toString(),
            }
        },
        getTypedValue() {
            switch (this.type) {
                case 'number':
                    return parseInt(this.currentValue ?? '0', 10)
                default:
                    return this.currentValue
            }
        },
    },
    watch: {
        currentValue: {
            handler(value) {
                this.currentValueChanged(value)
                this.validateValue()
            },
            deep: true,
        },
        modelValue: {
            handler(value) {
                if (this.currentValue != value) {
                    this.currentValue = value
                    this.validateValue()
                }
            },
            deep: true,
        },
        required: {
            handler() {
                this.validateValue()
            },
            deep: true,
        },
    },
}
</script>
