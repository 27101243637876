<template>
    <div :class="containerClass">
        <h3 v-if="h3">{{ h3 }}</h3>
        <label class="form-checkbox">
            <input v-model="currentValue" :placeholder="h3" type="checkbox" />
            {{ placeholder }}
        </label>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export class checkboxInputModel {
    constructor() {
        this.formComponent = null
        this.isValid = true
    }
}

export default {
    props: {
        modelValue: null,
        checkboxInput: {
            type: checkboxInputModel,
        },
        h3Key: {
            type: String,
            default: null,
        },
        h3Value: {
            type: String,
            default: null,
        },
        placeholderKey: {
            type: String,
            default: null,
        },
        placeholderValue: {
            type: String,
            default: null,
        },
        containerClass: {
            type: String,
            default: 'col-xl-3 col-sm-6 col-xs-12',
        },
    },
    data() {
        return {
            currentValue: null,
            checkboxInputModel: null,
            isValid: true,
        }
    },
    mounted: function () {
        this.checkboxInputModel = this.checkboxInput
        this.checkboxInputModel.formComponent = this
        this.currentValue = this.modelValue
    },
    methods: {
        currentValueChanged: function (value) {
            this.$emit('valueChanged', value)
            this.$emit('update:modelValue', value)
        },
    },
    computed: {
        ...mapGetters(['translate', 'currentLanguage', 'languages']),
        h3() {
            return this.translate(this.h3Key, this.h3Value)
        },
        placeholder() {
            return this.translate(this.placeholderKey, this.placeholderValue)
        },
        postValue() {
            return {
                label: this.translate(this.h3Key, this.h3Value),
                value: this.currentValue,
                valueLabel: this.translate(
                    this.placeholderKey,
                    this.placeholderValue
                ),
            }
        },
    },
    watch: {
        currentValue: {
            handler(value) {
                this.currentValueChanged(value)
            },
            deep: true,
        },
        modelValue: {
            handler(value) {
                this.currentValue = value
            },
            deep: true,
        },
    },
}
</script>
