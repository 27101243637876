<template>
    <div v-if="!loading && !user" class="container-fluid bottom-space">
        <div class="row">
            <div class="col-xl-3 col-sm-6 col-xs-12 mx-auto">
                <h3>
                    {{ translate('Formulier.UserName', 'Gebruiker') }}
                </h3>
                <input
                    v-model="userName"
                    :placeholder="translate('Formulier.UserName', 'Gebruiker')"
                    class="form-input"
                    type="text"
                    @keyup.enter="loginMember()"
                />
                <h3>
                    {{ translate('Formulier.Password', 'Wachtwoord') }}
                </h3>
                <input
                    v-model="password"
                    :placeholder="translate('Formulier.Password', 'Wachtwoord')"
                    class="form-input"
                    type="password"
                    @keyup.enter="loginMember()"
                />
                <div v-if="loginError" class="error">
                    {{ loginError }}
                </div>
                <button
                    @click="loginMember()"
                    class="form-button neut-form login"
                    :disabled="!user && !password"
                >
                    {{ translate('Formulier.Login', 'Login') }}
                </button>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
export default {
    name: 'Login',
    data() {
        return {
            userName: null,
            password: null,
        }
    },
    methods: {
        ...mapActions(['login', 'resetloginError']),
        loginMember() {
            this.resetloginError()
            this.login({
                userName: this.userName,
                password: this.password,
                rememberMe: true,
            })
        },
    },
    computed: {
        ...mapGetters(['translate', 'loading', 'user', 'loginError']),
    },
}
</script>
