<template>
    <div>
        <div v-if="!loading" class="container-fluid default-width">
            <div class="row align-items-start">
                <div class="col-6 header-image">
                    <a href="/"><Logo /></a>
                </div>
                <div class="col-6 form-lang-holder">
                    <button
                        v-if="user"
                        class="form-button-lang"
                        @click="logout()"
                    >
                        {{ translate('Formulier.Logout', 'Logout') }}
                    </button>
                    <button
                        v-for="lang in languages"
                        :key="lang"
                        class="form-button-lang"
                        :disabled="currentLanguage == lang"
                        @click="switchLanguage(lang)"
                    >
                        {{ lang }}
                    </button>
                </div>
            </div>
        </div>
        <div v-if="!loading" class="page-title-bg">
            <div class="page-title-bg-image">
                <div class="container-fluid default-width">
                    <div class="row align-items-start">
                        <div class="col-12 title">
                            {{
                                translate(
                                    'Formulier.Bestelformulier IsoStone',
                                    'Bestelformulier IsoStone'
                                )
                            }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="!loading" class="page-title">
            <div class="container-fluid default-width">
                <div class="row align-items-start">
                    <div class="col-12 title">
                        {{
                            translate(
                                'Formulier.Bestelformulier IsoStone',
                                'Bestelformulier IsoStone'
                            )
                        }}
                    </div>
                </div>
            </div>
        </div>
        <div
            v-if="!loading && user"
            class="container-fluid bottom-space default-width"
        >
            <div class="row align-items-start bottom-space">
                <TextInputX
                    v-model="info.klantnummer"
                    :textInput="info.klantnummerPost"
                    :h3Key="'Formulier.Klantnummer'"
                    :h3Value="'Klantnummer'"
                    required="klantnummer"
                    :validate="validate"
                    disabled="disabled"
                    :placeholderKey="'Formulier.Klantnummer'"
                    :placeholderValue="'Klantnummer'"
                    ref="inputText-klantnummer"
                />
                <TextInputX
                    v-model="info.naam"
                    :textInput="info.naamPost"
                    :h3Key="'Formulier.Naam'"
                    :h3Value="'Naam'"
                    required="naam"
                    :validate="validate"
                    :placeholderKey="'Formulier.Naam'"
                    :placeholderValue="'Naam'"
                    ref="inputText-naam"
                />
                <TextInputX
                    v-model="info.email"
                    :textInput="info.emailPost"
                    :h3Key="'Formulier.E-mail'"
                    :h3Value="'E-mail'"
                    required="email"
                    typeValue="email"
                    :validate="validate"
                    :placeholderKey="'Formulier.E-mail'"
                    :placeholderValue="'E-mail'"
                    ref="inputText-email"
                />
                <TextInputX
                    v-model="info.leverdatum"
                    :textInput="info.leverdatumPost"
                    :h3Key="'Formulier.Leverdatum'"
                    :h3Value="'Leverdatum'"
                    required="leverdatum"
                    typeValue="date"
                    :validate="validate"
                    pattern="\d{4}-\d{2}-\d{2}"
                    :min-value="10"
                    :placeholderKey="'Formulier.dd-mm-jjjj'"
                    :placeholderValue="'dd-mm-jjjj'"
                    ref="inputText-leverdatum"
                />
                <TextInputX
                    v-model="info.ordernummer"
                    :textInput="info.ordernummerPost"
                    :h3Key="'Formulier.Ordernummer'"
                    :h3Value="'Ordernummer'"
                    required="ordernummer"
                    :validate="validate"
                    :placeholderKey="'Formulier.Ordernummer'"
                    :placeholderValue="'Ordernummer'"
                    ref="inputText-ordernummer"
                />
            </div>

            <div class="row align-items-start bottom-space">
                <div class="col-12">
                    <div
                        class="form-button-holder"
                        v-for="(value, index) in forms"
                        :key="index"
                    >
                        <button
                            @click="setForm(index)"
                            class="form-button neut-form"
                            :disabled="index == form"
                            :class="errorBtnClass(index)"
                        >
                            {{ translate('Formulier', 'Formulier') }} #{{
                                index + 1
                            }}</button
                        ><button
                            @click="removeForm(index)"
                            class="form-button remove"
                            :disabled="index == form"
                        >
                            &nbsp;
                        </button>
                    </div>
                </div>
            </div>

            <div class="row align-items-start bottom-space">
                <div class="col-12">
                    <button
                        @click="createNewForm()"
                        class="form-button neut-form"
                    >
                        {{ translate('Formulier.Nieuw', 'Nieuw') }}
                    </button>
                    <button @click="createNewForm()" class="form-button add">
                        &nbsp;
                    </button>
                </div>
            </div>
        </div>
        <div v-if="!loading && user">
            <NeutForm
                v-show="form == fIndex"
                v-for="(value, fIndex) in forms"
                :key="fIndex"
                :initial-form="value"
                :validate="validate"
                :ref="'neutForms' + fIndex"
            />
        </div>
        <div
            v-if="!loading && user"
            class="container-fluid bottom-space default-width"
        >
            <div class="row align-items-start">
                <div class="row align-items-end bottom-space">
                    <div class="col-12 form-send-holder">
                        <div v-if="validate && !isFormValid" class="error-msg">
                            {{
                                translate(
                                    'Formulier.Niet alle velden zijn gevuld',
                                    'Niet alle velden zijn gevuld'
                                )
                            }}
                        </div>
                        <div v-if="sendError" class="error-msg">
                            {{
                                translate(
                                    'Formulier.Fout opgetreden',
                                    'Fout opgetreden'
                                )
                            }}
                        </div>
                        <div v-if="sendSuccessful" class="successful-msg">
                            {{
                                translate(
                                    'Formulier.Formulier verzonden?',
                                    'Formulier verzonden?'
                                )
                            }}
                        </div>
                        <div v-if="readyToSend" class="successful-msg">
                            {{
                                translate(
                                    'Formulier.Klaar om te verzenden',
                                    'Klaar om te verzenden?'
                                )
                            }}
                        </div>
                        <button
                            v-if="!readyToSend && !sendSuccessful"
                            @click="sendForm()"
                            class="form-button form-button-send form-button-ready-to-send"
                        >
                            {{ translate('Formulier.Verzenden', 'Verzenden') }}
                        </button>
                        <button
                            v-if="readyToSend"
                            :disabled="sending"
                            @click="send()"
                            class="form-button form-button-send form-button-ready-to-send"
                        >
                            {{ translate('Formulier.Ja', 'Ja') }}
                        </button>
                        <span
                            v-if="readyToSend && sending"
                            class="loader-dual-ring"
                        ></span>
                        <button
                            v-if="readyToSend"
                            :disabled="sending"
                            @click="dontSendForm()"
                            class="form-button form-button-send form-button-ready-to-send"
                        >
                            {{ translate('Formulier.Nee', 'Nee') }}
                        </button>
                        <div
                            v-if="readyToSend"
                            class="form-ready-to-send"
                        ></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Logo from './Logo'
import TextInputX from './TextInputX'
import NeutForm from './NeutForm'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import axios from 'axios'
import { dropDownModel } from './DropDownX.vue'
import { textInputModel } from './TextInputX.vue'
import { checkboxInputModel } from './CheckboxInputX.vue'

const https = axios.create({
    withCredentials: true,
})

export class formModel {
    constructor() {}
    postValue() {
        return []
    }
}

export class dorpelNeutModel {
    constructor() {}
    postValue() {
        return []
    }
}

export class dorpelPaneelModel {
    constructor() {}
    postValue() {
        return []
    }
}

export default {
    name: 'Root Form',
    components: {
        Logo,
        TextInputX,
        NeutForm,
    },
    data() {
        return {
            title: 'Bestelformulier',
            info: {
                klantnummer: '',
                klantnummerPost: new textInputModel(),
                naam: null,
                naamPost: new textInputModel(),
                email: null,
                emailPost: new textInputModel(),
                leverdatum: '',
                leverdatumPost: new textInputModel(),
                ordernummer: '',
                ordernummerPost: new textInputModel(),
                isValid: true,
            },
            forms: [],
            form: 0,
            isLoaded: false,
            validate: false,
            sendSuccessful: false,
            errors: [],
            readyToSend: false,
            sending: false,
            sendError: false,
            leverdatumType: 'date',
            isValid: false,
        }
    },
    created: function () {
        this.init()
        this.createNewForm()
        this.validateForm()
    },
    methods: {
        ...mapActions(['init', 'logout']),
        ...mapMutations(['switchLanguage']),
        createNewForm() {
            if (this.user && this.formData) {
                const form = {
                    systeemhuis: null,
                    systeemhuisPost: new dropDownModel(),
                    dorpel: null,
                    dorpelPost: new dropDownModel(),
                    aantalStijlen: 'A1',
                    aantalStijlenPost: new dropDownModel(),
                    pakking: null,
                    pakkingPost: new dropDownModel(),
                    doppen: true,
                    doppenPost: new checkboxInputModel(),
                    kleur: null,
                    kleurPost: new dropDownModel(),
                    bewerkingLinks: null,
                    bewerkingLinksPost: new dropDownModel(),
                    bewerkingRechts: null,
                    bewerkingRechtsPost: new dropDownModel(),
                    merk: null,
                    merkPost: new textInputModel(),
                    hasNaamOnderaanslag: false,
                    hasNaamOnderaanslagPost: new checkboxInputModel(),
                    naamOnderaanslag: null,
                    naamOnderaanslagPost: new dropDownModel(),
                    aantalDorpels: 1,
                    aantalDorpelsPost: new textInputModel(),
                    total: null,
                    totalPost: new textInputModel(),
                    sluitplaat: null,
                    sluitplaatPost: new dropDownModel(),
                    sluitplaatFrezenLengte: null,
                    sluitplaatFrezenLengtePost: new textInputModel(),
                    sluitplaatFrezenHoogte: null,
                    sluitplaatFrezenHoogtePost: new textInputModel(),
                    sluitplaatPositieBegin: null,
                    sluitplaatPositieBeginPost: new textInputModel(),
                    sluitplaatVoorkantDam: null,
                    sluitplaatVoorkantDamPost: new textInputModel(),
                    extraArtikelen: null,
                    extraArtikelenPost: new textInputModel(),
                    dorpelNeuten: [],
                    dorpelPanelen: [],
                    isValid: false,
                }
                for (
                    var i = 0;
                    i <=
                    this.formData.aantalKozijnStijlen[
                        this.formData.aantalKozijnStijlen.length - 1
                    ].value.value.length -
                        1;
                    i++
                ) {
                    form.dorpelNeuten.push({
                        label: this.formData.aantalKozijnStijlen[
                            this.formData.aantalKozijnStijlen.length - 1
                        ].value.value[i],
                        boorPatroon: null,
                        boorPatroonPost: new dropDownModel(),
                        vulblok: null,
                        vulblokPost: new dropDownModel(),
                        bevestigingsGaten: null,
                        neut: null,
                        neutPost: new dropDownModel(),
                        vorm: form.dorpelNeuten.length == 0 ? 'L' : 'M',
                        vormPost: new dropDownModel(),
                    })
                    form.dorpelPanelen.push({
                        label: this.formData.aantalKozijnStijlen[
                            this.formData.aantalKozijnStijlen.length - 1
                        ].value.value[i],
                        maat: null,
                        maatPost: new textInputModel(),
                        afwatering: false,
                        afwateringPost: new checkboxInputModel(),
                        bevestigingsGatenPost: new textInputModel(),
                        epdmStrip: null,
                        epdmStripPost: new dropDownModel(),
                        amStrip: null,
                        amStripPost: new dropDownModel(),
                    })
                }
                form.dorpelNeuten.push({
                    label: 'X',
                    boorPatroon: null,
                    boorPatroonPost: new dropDownModel(),
                    vulblok: null,
                    vulblokPost: new dropDownModel(),
                    bevestigingsGaten: null,
                    bevestigingsGatenPost: new textInputModel(),
                    neut: null,
                    neutPost: new dropDownModel(),
                    vorm: 'R',
                    vormPost: new dropDownModel(),
                })
                this.forms.push(form)
                this.form = this.forms.length - 1
            }
        },
        removeForm(index) {
            if (this.forms.length > 1) {
                this.forms.splice(index, 1)
                if (index < this.form) {
                    this.setForm(this.form - 1)
                    return
                }
                if (this.forms.length <= this.form) {
                    this.setForm(0)
                    return
                }
            }
        },
        setForm(index) {
            this.form = index
        },
        addForm() {
            this.createNewForm()
            this.form = this.forms.length - 1
        },
        sendForm() {
            this.validate = true
            const that = this
            if (that.isFormValid) {
                that.readyToSend = true
            }
        },
        dontSendForm() {
            this.validate = false
            this.readyToSend = false
        },
        send() {
            this.validate = true
            if (this.isFormValid && !this.sending) {
                this.sending = true
                let postModel = {
                    klantnummer:
                        this.info.klantnummerPost.formComponent.postValue,
                    naam: this.info.naamPost.formComponent.postValue,
                    eMail: this.info.emailPost.formComponent.postValue,
                    leverdatum:
                        this.info.leverdatumPost.formComponent.postValue,
                    ordernummer:
                        this.info.ordernummerPost.formComponent.postValue,
                    dorpels: [],
                    language: this.currentLanguage,
                }
                for (let i = 0; i < this.forms.length; i++) {
                    postModel.dorpels.push(
                        this.$refs['neutForms' + i][0].postData()
                    )
                }
                const that = this
                https
                    .post('/dataneuten', postModel)
                    .then(function (result) {
                        if (result.data.data) {
                            that.validate = false
                            that.forms = []
                            that.sendSuccessful = true
                            that.readyToSend = false
                            setTimeout(() => {
                                that.addForm()
                                that.form = 0
                                that.sendSuccessful = false
                                that.sending = false
                            }, 5000)
                        } else if (!result.data.user) {
                            that.sendSuccessful = false
                            that.readyToSend = false
                            that.sending = false
                            that.setUser(null)
                        } else {
                            that.sending = false
                            that.validate = false
                            that.sendSuccessful = false
                            that.readyToSend = false
                            that.sendError = true
                            setTimeout(() => {
                                that.sendError = false
                            }, 5000)
                        }
                    })
                    .catch(function () {
                        that.sending = false
                        that.validate = false
                        that.sendSuccessful = false
                        that.readyToSend = false
                        that.sendError = true
                        setTimeout(() => {
                            that.sendError = false
                        }, 5000)
                    })
            }
        },
        timeout(ms) {
            return new Promise((resolve) => setTimeout(resolve, ms))
        },
        validateForm() {
            console.log('validateForm 1')
            const props = Object.getOwnPropertyNames(this.info)
            for (let i = 0; i < props.length; i++) {
                if (props[i].endsWith('Post')) {
                    if (!this.info[props[i]].isValid) {
                        console.log('validateForm 2', i, props[i])
                        this.info.isValid = false
                        return
                    }
                }
            }
            this.info.isValid = true
        },
    },
    computed: {
        ...mapGetters([
            'formData',
            'translate',
            'currentLanguage',
            'languages',
            'loading',
            'user',
        ]),
        as() {
            return (form) => {
                if (!form.aantalStijlen) {
                    return []
                }
                return form.as.filter((obj) =>
                    form.aantalStijlen.value.includes(obj.label)
                )
            }
        },
        errorBtnClass() {
            return (form) => {
                if (!this.validate) {
                    return null
                }
                return {
                    'form-btn-error':
                        this.forms.length <= form || !this.forms[form].isValid,
                }
            }
        },
        isFormValid() {
            if (!this.validate) {
                return true
            }
            if (!this.info.isValid) {
                return false
            }
            const found = this.forms.find((form) => {
                return !form.isValid
            })
            if (found) {
                return false
            }
            return true
        },
    },
    watch: {
        info: {
            handler() {
                this.validateForm()
            },
            deep: true,
        },
        user: {
            handler(newVal) {
                if (newVal) {
                    if (this.forms.length == 0) {
                        this.createNewForm()
                    }
                    this.info.klantnummer = newVal.userName
                }
            },
            deep: true,
        },
    },
}
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
