<template>
    <div :class="styleClass">
        <h3 v-if="showH3 && h3">{{ h3 }}</h3>
        <Multiselect
            v-model="selectedValue"
            :can-deselect="false"
            :can-clear="canDeselect"
            :disabled="!options || options.length == 0"
            :options="options"
            :value="selectedValue"
            :searchable="searchable"
            :track-by="trackBy"
            :placeholder="placeholder"
            :locale="currentLanguage"
            ref="multiselect"
            fallback-locale="nl"
            :on-create="handleOptions"
        ></Multiselect>
    </div>
</template>

<script>
import Multiselect from '@vueform/multiselect'
import { mapGetters } from 'vuex'

export class dropDownModel {
    constructor() {
        this.formComponent = null
        this.isValid = true
    }
}

export default {
    components: {
        Multiselect,
    },
    props: {
        modelValue: null,
        dropDown: {
            type: dropDownModel,
        },
        optionsValue: {
            type: Array,
            default: null,
        },
        h3Value: {
            type: String,
            default: null,
        },
        h3Key: {
            type: String,
            default: null,
        },
        showH3: {
            type: Boolean,
            default: true,
        },
        placeholderValue: {
            type: String,
            default: null,
        },
        placeholderKey: {
            type: String,
            default: null,
        },
        trackBy: null,
        searchable: {
            type: Boolean,
            default: false,
        },
        required: {
            type: String,
            default: null,
        },
        validate: {
            type: Boolean,
            default: false,
        },
        containerClass: {
            type: String,
            default: 'col-xl-3 col-sm-6 col-xs-12',
        },
    },
    data() {
        return {
            selectedValue: null,
            selectedValueLabel: null,
            dropDownModel: null,
            options: [],
            isValid: false,
            isMounted: false,
        }
    },
    mounted: function () {
        this.dropDownModel = this.dropDown
        this.dropDownModel.formComponent = this
        this.isMounted = true
        setTimeout(() => {
            this.setOptions()
            this.selectedValue = this.modelValue
            this.setOption()
            this.validateValue()
        }, 100)
    },
    unmounted: function () {
        this.isValid = true
        this.dropDownModel.isValid = true
    },
    methods: {
        log: function (...params) {
            if (this.h3Key.toLowerCase().indexOf('boorpatroon') > -1) {
                console.log(...params)
            }
        },
        handleOptions: function () {
            this.setOption()
        },
        selectedValueChanged: function (value) {
            this.$emit('valueChanged', value)
            this.$emit('update:modelValue', value)
        },
        optionsChanged: function () {
            this.setOptions()
            this.setOption()
        },
        resetDefault: function () {
            if (this.options.find((obj) => obj.isDefault)) {
                this.selectedValue = null
            }
        },
        setOption: function () {
            if (this.options) {
                let found = null
                let value = null
                if (this.selectedValue) {
                    value = this.selectedValue
                }

                if (value) {
                    found = this.options.find((obj) => obj.value == value)
                }

                if (!found) {
                    found = this.options.find((obj) => obj.isDefault)
                }

                if (found) {
                    this.$refs.multiselect.clear()
                    this.$refs.multiselect.select(found)
                    return
                }
            }
            this.selectedValue = null
            this.$refs.multiselect?.clear()
        },
        setOptions() {
            const options = []
            for (let i = 0; i < this.optionsValue.length; i++) {
                const value = this.optionsValue[i].value
                const label = this.optionsValue[i].label
                const langauges = {}
                for (let l = 0; l < this.languages.length; l++) {
                    langauges[this.languages[l]] = this.translation(
                        this.h3Key + ' - ' + label,
                        label,
                        this.languages[l]
                    )
                }
                options.push({
                    label: langauges,
                    value: value,
                    isDefault: this.optionsValue[i].isDefault,
                })
            }

            this.options = options
            this.resetDefault()
        },
        validateValue() {
            this.setIsValid(this.required && !this.selectedValue ? false : true)
        },
        setIsValid(isValid) {
            if (this.isValid != isValid) {
                this.isValid = isValid
                this.dropDownModel.isValid = isValid
            }
        },
    },
    computed: {
        ...mapGetters([
            'translate',
            'translation',
            'currentLanguage',
            'languages',
        ]),
        h3() {
            return this.translate(this.h3Key, this.h3Value)
        },
        placeholder() {
            return this.translate(this.placeholderKey, this.placeholderValue)
        },
        canDeselect() {
            return (
                this.required == undefined ||
                this.required == null ||
                this.required == ''
            )
        },
        disabled() {
            return !this.options || this.options.length == 0
        },
        postValue() {
            let selectedValueLabel = null
            const found = this.options.find(
                (obj) => this.selectedValue && obj.value == this.selectedValue
            )

            if (found) {
                selectedValueLabel = found.label[this.currentLanguage]
            } else {
                selectedValueLabel = this.selectedValue
            }
            return {
                label: this.translate(this.h3Key, this.h3Value),
                value: this.selectedValue,
                valueLabel: selectedValueLabel,
            }
        },
        styleClass() {
            const styleClass = []
            styleClass.push(this.containerClass)
            if (this.validate && !this.isValid) {
                styleClass.push('form-prop-error')
            }
            return styleClass.join(' ')
        },
    },
    watch: {
        selectedValue: {
            handler(value) {
                if (this.isMounted && this.modelValue != value) {
                    this.selectedValueChanged(value)
                    this.validateValue()
                }
            },
            deep: true,
        },
        optionsValue: {
            handler(value) {
                if (this.isMounted) {
                    this.optionsChanged(value)
                    this.validateValue()
                }
            },
            deep: true,
        },
        modelValue: {
            handler(value) {
                if (this.isMounted && this.selectedValue !== value) {
                    this.selectedValue = value
                }
            },
            deep: true,
        },
        required: {
            handler() {
                this.validateValue()
            },
            deep: true,
        },
    },
}
</script>
