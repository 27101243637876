import Cookies from 'js-cookie'
import { createStore } from 'vuex'
import axios from 'axios'
const https = axios.create({
    withCredentials: true,
})
export default createStore({
    state: {
        formData: {
            systeemhuizen: [],
            dorpelTypes: [],
            pakkingen: [],
            kleuren: [],
            aantalKozijnStijlen: [],
            bewekingLinks: [],
            bewekingRechts: [],
            hartMaten: [],
            as: [],
            boorPatronen: [],
            vulblokken: [],
            afwatering: [],
            epdmStrips: [],
            amStrips: [],
            sluitplaten: [],
            neuten: [],
        },
        translations: {},
        languages: [],
        currentLanguage: '',
        loading: true,
        user: null,
        loginError: null,
    },
    getters: {
        formData(state) {
            return state.formData
        },
        translate: (state) => (key, defaultValue) => {
            return (
                state.translations[state.currentLanguage]?.[key] ??
                defaultValue ??
                key
            )
        },
        translation: (state) => (key, defaultValue, lang) => {
            return state.translations[lang]?.[key] ?? defaultValue ?? key
        },
        languages(state) {
            return state.languages
        },
        currentLanguage(state) {
            return state.currentLanguage
        },
        loading(state) {
            return state.loading
        },
        user(state) {
            return state.user
        },
        loginError(state) {
            return state.loginError
        },
    },
    mutations: {
        setTranslations(state, data) {
            state.languages = Object.getOwnPropertyNames(data)
            let lang = state.languages[0]
            if (Cookies.get('form-langauge')) {
                const cookieLang = Cookies.get('form-langauge')
                if (state.languages.includes(cookieLang)) {
                    lang = cookieLang
                }
            }
            state.currentLanguage = lang
            state.translations = data
        },
        setFormData(state, data) {
            state.formData = data
            state.loading = false
        },
        switchLanguage(state, lang) {
            state.currentLanguage = lang
            Cookies.set('form-langauge', lang, { expires: 31 })
        },
        setUser(state, user) {
            state.user = user
        },
        setloginError(state, msg) {
            state.loginError = msg
        },
    },
    actions: {
        init(context, lang) {
            https
                .get('/dataneuten')
                .then(function (response) {
                    context.commit(
                        'setTranslations',
                        response.data.translations,
                        lang
                    )
                    context.commit('setFormData', response.data.formData)
                    context.commit('setUser', response.data.user)
                })
                .catch(function () {})
        },
        login(context, model) {
            https
                .post('/loginmember', model)
                .then(function (response) {
                    if (response.data.success) {
                        context.commit('setUser', response.data.data)
                    } else {
                        context.commit('setloginError', response.data.message)
                    }
                })
                .catch(function () {})
        },
        resetloginError(context) {
            context.commit('setloginError', null)
        },
        logout(context) {
            https
                .post('/logoutmember')
                .then(function () {
                    context.commit('setUser', null)
                })
                .catch(function () {})
        },
    },
})
